.HeaderLinkWithList {
  padding-bottom: 1.5625rem;

  @include media('md-up') {
    padding-bottom: 3.125rem;
  }

  &__header {
    @extend .text-lg-mobile;
    color: color('bronze');

    > span:hover {
      color: color('black');
    }

    @include media('md-up') {
      font-size: 3.125rem;
      line-height: 3.4375rem;
    }
  }

  &__list-item {
    @extend .text-md-mobile;

    @include media('md-up') {
      font-size: 1.875rem;
      line-height: 2.125rem;
    }
  }
}
