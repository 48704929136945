.AuthorList {
  max-width: 90vw;

  &--style-white,
  &--style-bronze {
    &__button:hover {
      color: color('black');
    }
  }

  &--style-black {
    &__button:hover {
      color: color('bronze-darker');
    }
  }

  @include media('sm-up') {
    max-width: unset;
  }
}
