.Quote {
  font-size: 1.875rem;
  line-height: 1.2;
  padding-top: 3.4375rem;
  padding-bottom: 3.4375rem;

  @include media('md-up') {
    font-size: 3.125rem;
    padding-top: 5.75rem;
    padding-bottom: 5.75rem;
  }
}
