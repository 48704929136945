.SocialMediaBlock {
  &__block {
    padding: 1.4375rem;

    @include media('sm-down') {
      min-width: 16.25rem;
    }
  }

  &__image-index-0 {
    top: 10%;
  }

  &__image-index-1 {
    top: 35%;
  }

  &__image-index-2 {
    top: 18%;
  }

  &__image-index-3 {
    top: 25%;
  }

  &__images-container {
    min-height: 25rem;

    @include media('lg-up') {
      min-height: 44.4375rem;
    }

    @include media('xxl-up') {
      min-height: 50rem;
    }
  }

  &__image-container {
    position: relative;

    &::after {
      display: block;
      content: '';
      padding-bottom: 100%
    }

    > img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;

    }
  }
}
