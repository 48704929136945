@font-face {
  font-family: 'Apercu';
  src: url('/assets/fonts/apercu-bold-webfont.eot') format('eot'),
    url('/assets/fonts/apercu-bold-webfont.woff2') format('woff2'),
    url('/assets/fonts/apercu-bold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Apercu';
  src: url('/assets/fonts/apercu-bolditalic-webfont.eot') format('eot'),
    url('/assets/fonts/apercu-bolditalic-webfont.woff2') format('woff2'),
    url('/assets/fonts/apercu-bolditalic-webfont.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Apercu';
  src: url('/assets/fonts/apercu-medium-webfont.eot') format('eot'),
    url('/assets/fonts/apercu-medium-webfont.ttf') format('ttf'),
    url('/assets/fonts/apercu-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Apercu';
  src: url('/assets/fonts/apercu-regular-webfont.eot') format('eot'),
    url('/assets/fonts/apercu-regular-webfont.woff') format('woff'),
    url('/assets/fonts/apercu-regular-webfont.ttf') format('ttf');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Apercu';
  src: url('/assets/fonts/apercu-italic-webfont.eot') format('eot'),
    url('/assets/fonts/apercu-italic-webfont.ttf') format('ttf'),
    url('/assets/fonts/apercu-italic-webfont.woff') format('woff');
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'ApercuMono';
  src: url('/assets/fonts/apercu-mono-regular.eot') format('eot'),
    url('/assets/fonts/apercu-mono-regular.ttf') format('ttf'),
    url('/assets/fonts/apercu-mono-regular.woff') format('woff');
  font-style: normal;
  font-display: swap;
}
