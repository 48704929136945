.FeaturedArticleBlock {
  
  &__text-container {
    &--style-white {
      border-top: $border-black;
    }
  
    &--style-black {
      border-top: $border-white;
    }
  }
}
