.Hero {

  @include media('md-up') {
    border-right: $border-black;
  }
  
  &__outer-image-container {
    border-right: $border-black;

    @include media('md-up') {
      border-right: 0;
    }
  }

  &__image-container {
    height: 70vh;

    @include media('lg-up') {
      height: 80vh;
    }
  }

  &__image {
    max-height: 50vh;
    max-width: 80vw;
    object-fit: contain;
  }

  &__article-title {
    border-right: $border-black;
    
    @include media('md-up') {
      border-right: 0;
    }
  }
}
