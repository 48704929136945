.NavFooterMenu {
  border-top: $border-black;
  margin-top: 1.375rem;
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;

  @include media('md-up') {
    margin-top: 0;
  }

  &__footer-icon-container {
    width: 9.625rem;
  }

  &__link {
    > span {
      @extend .link--style-hover-black;
    }
  }
}
