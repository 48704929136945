.ImageTextBlock {
  &--border-top-none {
    @include media('md-up') {
      border-top: 0;
    }
  }

  &__title,
  &__button {
    @include media('md-up') {
      line-height: 1;
      font-size: 3.125rem;
    }

    @include media('lg-up') {
      font-size: 5rem;
    }
  }

  &__button {
    &--style-bronze {
      color: color('white');

      @extend .link--style-hover-black;
    }

    &--style-black {
      color: color('bronze');

      &:hover {
        color: color('bronze-darker');
      }
    }

    &--style-white {
      color: color('bronze');

      @extend .link--style-hover-black;
    }
  }

  &__container {
    @include media('md-up') {
      min-height: 24rem;
    }

    @include media('lg-md-up') {
      min-height: 26rem;
    }

    @include media('lg-up') {
      min-height: 32rem;
    }

    @include media('xxl-up') {
      min-height: 35rem;
    }
  }

  &__text-container {
    &--style-event {
      @include media('md-up') {
        padding: 0.4rem 4rem 4rem 0.4rem;
      }

      @include media('xl-up') {
        padding: 0.4rem 4rem 7.3rem 0.4rem;
      }
    }
  }
}
