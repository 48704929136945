.FacultyMembersBlock {
  width: calc(100vw - #{$site-padding-x-sm-double});

  @include media('md-up') {
    width: calc(100vw - #{$site-padding-x-md-double});
  }

  &__title {
    padding: 0.625rem 0.625rem 3.375rem 0.625rem;

    @include media('md-up') {
      padding: 0.9375rem 0.9375rem 1.0625rem 0.9375rem;
    }

    &--style-search {
      line-height: 1;
      padding: 0.8125rem 0.4375rem 0.8125rem 0.4375rem;

      @include media('md-up') {
        padding: 3.5625rem 0.875rem 0.375rem 0.875rem;
      }
    }
  }

  &__item {
    padding: 1rem 0.4rem 1rem 0.4rem;

    &--style-search {
      padding: 1rem 0.75rem 1rem 0.75rem;
    }

    @include media('lg-md-down') {
      border-bottom: $border-black;
      min-width: 13rem;
      max-width: 13rem;
    }
  }

  &__item-container {
    @include media('lg-md-down') {
      &:last-of-type > div {
        border-bottom: 0;
      };
    }

    @include media('lg-up') {
      border-bottom: $border-black;

      &:last-of-type {
        border-bottom: 0;
      };

    }
  }
}
