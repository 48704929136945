.Model3D {
  height: 30vh;

  @include media('sm-xs-up') {
    height: 40vh;
  }

  @include media('md-up') {
    height: 55vh;
  }

  @include media('xl-up') {
    height: 80vh;
  }

  &__model {
    &:focus {
      outline: none;
    }
  }
}
