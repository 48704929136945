.NotificationBar {
  transform: translate3d(0, -$notification-marquee-height-desktop, 0);

  &--active {
    transform: translate3d(0, 0, 0);
  }

  &__banner {
    max-height: 0;
    height: 0;

    &--active {
      height: calc(#{$notification-banner-height-desktop} - 0.625rem);
      max-height: 20rem;
      margin-bottom: 0.625rem;
      border-bottom: $border-white;
    }
  }

  &__marquee {
    @include media('md-up') {
      height: $notification-marquee-height-tablet;
    }

    @include media('xl-lg-up') {
      height: $notification-marquee-height-desktop;
    }

    &__text {
      padding-left: 100%;
      animation: $transition-animate-marquee;
      white-space: nowrap;

      &:hover {
        animation-play-state: paused;
      }
    }
  }

  &__gradient {
    background: color('black');
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.15396126760563378) 18%,
      rgba(0, 0, 0, 1) 40%
    );
  }

  &__image-container {
    height: 14.75rem;
  }

  &__sticker {
    @include media('md-up') {
      height: 13.6875rem;
      max-width: 20rem;
      top: 4.5rem;
      right: 5rem;
    }

    &:hover {
      transform: rotate(5deg);
    }
  }
}
