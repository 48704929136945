.ArticleAuthorsReferences {

  @include media('md-up') {
    padding-bottom: 10.8125rem;
  }

  &__paragraph {

    > p > a {
      text-decoration: none;
      color: color('bronze');
      word-break: break-word;
    }
  }

}
