.SearchComponent {

  &__search-field > .TextField > div > input,
  &__enter-button {
    padding-top: 0.75rem;
    padding-bottom: 1.375rem;
    padding-left: 0.625rem;

    @include media('md-up') {
      padding-bottom: 1.8125rem;
      padding-left: 0.75rem;
    }
  }

  &__no-results-container {
    flex-grow: 5;
  }

  &__search-results {    
    &--no-results {
      min-height: 20rem;

      @include media('lg-up') {
        min-height: 30rem;
      }
    }
  }

  &__research-header {
    line-height: 1;
    padding: 0.8125rem 0.4375rem 0.8125rem 0.4375rem;

    @include media('md-up') {
      padding: 3.5625rem 0.875rem 0.375rem 0.875rem;
    }
  }
}
