//Global
$site-padding-x-sm: 0.5rem;
$site-padding-x-sm-double: 1rem;
$site-padding-x-md: 0.625rem;
$site-padding-x-md-double: 1.25rem;

//Nav and Notification Bar
$primary-nav-height-xs: 4.5625rem;
$primary-nav-height-sm-up: 2.775625rem;
$primary-nav-height-md-up: 2.2546875rem;
$primary-nav-height-xl-lg-up: 3.8125rem;
$secondary-nav-height-desktop: 3.5625rem;
$nav-secondary-nav-height-md-up: 5.029375rem;
$nav-secondary-nav-height-xl-lg-up: 7.375rem;
$nav-notification-fully-open-tablet: 23.367495rem;
$nav-notification-fully-open-desktop: 26.75rem;
$full-notification-height-desktop: 19.375rem;
$full-notification-height-tablet: 18.33812rem;
$notification-marquee-height-tablet: 2.775625rem;
$notification-marquee-height-desktop: 3.8125rem;
$notification-banner-height-desktop: 15.5625rem;
$primary-nav-notification-marquee-height-tablet: 6.875rem;
$primary-nav-notification-marquee-height-desktop: 8.125rem;

//Cookie Consent
$cookie-consent-width: 18.75rem;

//Article
$article-inner-content-padding-x-sm: 1.3125rem;
$ordered-list-padding-left: 2.75rem;
