.LinkList {
  &--style-article {
    &__link-title {
      @include media('lg-up') {
        padding-bottom: 2.375rem;
      }
    }
  }

  &--style-nav {
    &__link-title {
      padding-bottom: 0.1875rem;
    }
  }

  &__link-title,
  &__link {
    @extend .text-md-mobile;

    @include media('md-up') {
      font-size: 1.875rem;
      line-height: 2.125rem;
    }
  }

  &__links-container {
    > a > div:hover {
      color: color('black');
    }
  }
}
