.TopicsListBlock {
  &__list-item {
    font-size: 1.25rem;
    letter-spacing: -0.02rem;
    line-height: 1.25rem;

    @include media('md-up') {
      font-size: 1.875rem;
      line-height: 2.125rem;
    }
  }

  &__title {
    padding: 0.5rem 0.5625rem 0.6875rem 0.5625rem;

    @include media('md-up') {
      padding: 0.625rem 0.625rem 2.5rem 0.625rem;
    }
  }
}
