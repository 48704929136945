$border-radius: (
  'none': 0rem
);

@function radius($radius) {
  @return map-get($border-radius, $radius);
}

@each $name, $radius in $border-radius {
  .radius-#{$name} {
    border-radius: $radius;
  }
}
