.GalleryBlock {
  width: calc(100vw - #{$site-padding-x-sm-double});

  @include media('md-up') {
    width: calc(100vw - #{$site-padding-x-md-double});
  }

  &__placeholder {
    min-width: 17rem;
  }

  &__block-container {
    overflow-x: scroll;
  }

  &__image-container {
    height: calc(((100vw - #{$site-padding-x-sm-double}) / 4) * 3);
    width: calc(((100vw - #{$site-padding-x-sm-double}) / 4) * 3);

    @include media('sm-xs-up') {
      height: calc(((100vw - #{$site-padding-x-sm-double}) / 6) * 3);
      width: calc(((100vw - #{$site-padding-x-sm-double}) / 6) * 3);
    }

    @include media('md-up') {
      height: calc(((100vw - #{$site-padding-x-md-double}) / 12) * 4);
      width: calc(((100vw - #{$site-padding-x-md-double}) / 12) * 4);
    }
  }

  &__image {
    object-fit: contain;
    max-height: 14rem;
    max-width: 13rem;

    @include media('sm-up') {
      max-height: 17rem;
      max-width: 17rem;
    }

    @include media('md-up') {
      max-height: 15rem;
      max-width: 12rem;
    }

    @include media('lg-md-up') {
      max-height: 17rem;
      max-width: 14rem;
    }

    @include media('lg-up') {
      max-height: 20rem;
      max-width: 17rem;
    }

    @include media('xl-up') {
      max-height: 23rem;
      max-width: 23rem;
    }

    @include media('xxl-up') {
      max-height: 25rem;
      max-width: 25rem;
    }
  }

  &__title-container > span {
    @include media('md-down') {
      font-size: 0.9375rem;
      line-height: 1.0625rem;
    }
  }

  &__title-container {
    height: 4.25rem;
    border-top: $border-black;

    @include media('sm-down') {
      border-bottom: 0;
    }

    @include media('md-up') {
      padding: 0.625rem;
      height: 10rem;
      border-top: $border-black;
    }

    @include media('xxl-up') {
      height: 8rem;
    }
  }
}
