.ImageModal {
  background-color: rgba(0, 0, 0, 0.89);

  &__image-container {
    > img {
      max-width: 20rem;
      width: 100%;
      object-fit: contain;

      @include media('sm-up') {
        max-width: unset;
      }

      @include media('md-up') {
        max-height: 40rem;
      }

      @include media('lg-up') {
        max-height: 50rem;
      }
    }
  }

  &__caption {
    font-size: 0.9375rem;
    max-width: 22rem;

    @include media('md-up') {
      font-size: 1.5625rem;
    }

    @include media('lg-up') {
      font-size: 1.875rem;
    }
  }
}
