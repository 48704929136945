//------Fonts------//

//TO-DO: add correct fonts
$apercu: 'Apercu', sans-serif;
$apercu-mono: 'ApercuMono', monospace;

.apercu {
  font-family: $apercu;
}

.apercu-mono {
  font-family: $apercu-mono;
}

//------Global------//

.line-height-xs {
  line-height: 1 !important;
}

.line-height-sm {
  line-height: 1.2 !important;
}
.text-lg,
.text-navigation,
.text-md,
.text-sm,
.text-xs,
.text-xxs,
.text-article-body,
.text-block-body,
.text-article-xs {
  font-family: $apercu;
  letter-spacing: -0.06rem;
}

.text-meta-sm,
.text-meta-xs,
.text-caption {
  font-family: $apercu-mono;
}

.text-lg-mobile {
  font-size: 2.1875rem;
  line-height: 2.4375rem;
}

.text-md-mobile {
  font-size: 1.5625rem;
  line-height: 1.2;
}

.text-sm-mobile {
  font-size: 0.9375rem;
  line-height: 1.2;
}

.text-lg {
  @extend .text-lg-mobile;

  @include media('md-up') {
    font-size: 5rem;
    line-height: 5.5rem;
  }
}

.text-md {
  @extend .text-md-mobile;

  @include media('md-up') {
    font-size: 3.125rem;
    line-height: 1.2;
  }
}

.text-sm {
  @extend .text-sm-mobile;

  @include media('md-up') {
    font-size: 1.875rem;
    line-height: 1.2;
  }
}

.text-xs {
  font-size: 1.25rem;
  line-height: 1.2;
}

.text-xxs {
  font-size: 0.9375rem;
  line-height: 1.0625rem;
}

//------Navigation------//

.text-navigation {
  font-size: 1.875rem;
  line-height: 1.875rem;

  @include media('xl-lg-up') {
    font-size: 3.125rem;
    line-height: 3.125rem;
  }
}

.text-navigation-link {
  font-size: 1.5625rem !important;
  line-height: 1.75rem !important;

  @include media('lg-up') {
    font-size: 1.875rem !important;
    line-height: 1.875rem !important;
  }
}

//------Secondary (Meta)------//

.text-meta-sm {
  font-size: 0.875rem;
  line-height: 0.9375rem;

  @include media('md-up') {
    font-size: 0.9375rem;
    line-height: 1.0625rem;
  }
}

.text-meta-xs {
  font-size: 0.75rem;
  line-height: 0.825rem;
}

//------Article------//

.text-article-body {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-left: calc(-#{$article-inner-content-padding-x-sm} + 0.4rem);

    @include media('lg-up') {
      margin-left: 0;
    }

    > span > a,
    > a {
      text-decoration: none;

      @extend .link--style-hover-black;
    }
  }
}

.text-article-body {
  p {
    line-height: 1.2;
  }

  > div p {
    padding-bottom: 2.5rem;
  }
}

.text-article-body,
.text-block-body {
  @extend .text-sm;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    > span > a,
    > a {
      text-decoration: none;
      color: color('bronze');

      @extend .link--style-hover-black;
    }
  }

  h1,
  h2 {
    @extend .text-lg;
    padding-top: 3rem;
    padding-bottom: 3rem;

    @include media('md-up') {
      padding-top: 6rem;
      padding-bottom: 6rem;
    }
  }

  h3 {
    @extend .text-md;
    padding-top: 2.0625rem;
    padding-bottom: 2.0625rem;

    @include media('md-up') {
      padding-top: 2.75rem;
      padding-bottom: 2.75rem;
    }
  }

  h4,
  h5,
  h6 {
    padding-top: 1.375rem;
    padding-bottom: 1.375rem;

    @include media('md-up') {
      padding-top: 2.75rem;
      padding-bottom: 2.75rem;
    }
  }

  h4 {
    @extend .text-sm;
    color: color('green');
  }

  h5,
  h6 {
    @extend .text-sm;
    color: color('black');
  }

  blockquote {
    @extend .text-sm;
    color: color('green');
    border-left: 1px solid color('green');
    padding-left: 1.875rem;
    margin-bottom: 2.5rem;
  }

  > div > p a {
    text-decoration: none;
    color: color('bronze');

    @extend .link--style-hover-black;
  }

  > div ul,
  > div ol {
    margin-bottom: 1rem;
  }

  ol,
  ul {
    > li a {
      text-decoration: none;
      color: color('bronze');

      @extend .link--style-hover-black;
    }
  }

  ol {
    list-style: none;
  }

  ul {
    list-style: none;
    position: relative;
  }

  ul li {
    &::before {
      content: '•';
      position: absolute;
      left: -2.75rem;
    }
  }
}

.text-article-xs {
  @extend .text-xxs;

  @include media('md-up') {
    font-size: 1.875rem;
    line-height: 1.875rem;
  }
}

.text-caption {
  @extend .text-meta-xs;

  @include media('md-up') {
    font-size: 0.875rem;
    line-height: 0.9375rem;
  }

  @include media('lg-up') {
    font-size: 0.9375rem;
    line-height: 1.0625rem;
  }
}

.text-block-body {
  h5 {
    @extend .text-md;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  > div p {
    padding-bottom: 2.5rem;
  }

  > div > .HeaderLinkWithList:first-of-type {
    padding-top: 1.5625rem;

    @include media('md-up') {
      padding-top: 3.125rem;
    }
  }
}
