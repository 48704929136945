.PageNotFound {
  &__image-container {
    height: 95vh;

    @include media('md-up') {
      height: 75vh;
    }

    > img {
      max-height: 40rem;
      object-fit: contain;
    }
  }
}
