.Nav {
  &__primary-nav {
    height: $primary-nav-height-xs;

    @include media('sm-up') {
      height: $primary-nav-height-sm-up;
    }

    @include media('xl-lg-up') {
      height: $primary-nav-height-xl-lg-up;
    }

    &--menu-active {
      height: $primary-nav-height-xs;

      @include media('sm-up') {
        height: $primary-nav-height-sm-up;
      }

      @include media('md-up') {
        height: 5.029375rem;
      }

      @include media('xl-lg-up') {
        height: $nav-secondary-nav-height-xl-lg-up;
      }
    }

    &--secondary-nav-active {
      height: 4.3125rem;

      @include media('sm-up') {
        height: $primary-nav-height-sm-up;
      }

      @include media('xl-lg-up') {
        height: $primary-nav-height-xl-lg-up;
      }
    }
  }

  &__main-menu {
    transform: translate3d(0, 0, 0);
    height: auto;

    &--closed {
      transform: translate3d(0, -2rem, 0);
      transform-style: preserve-3d;
      height: 0;
    }
  }

  &__main-menu-inner-container {
    @include media('md-up') {
      border-top: $border-black;
    }
  }

  &__primary-nav-content {
    @include media('xl-up') {
      height: $primary-nav-height-xl-lg-up;
    }

    &--menu-active {
      @include media('sm-up') {
        height: $primary-nav-height-sm-up;
      }

      @include media('xl-lg-up') {
        height: $primary-nav-height-xl-lg-up;
      }
    }
  }

  &__menu-secondary-nav {
    height: $primary-nav-height-sm-up;

    @include media('md-up') {
      margin-left: -0.15rem;
      height: $primary-nav-height-md-up;
    }

    @include media('lg-up') {
      margin-left: -0.25rem;
    }

    @include media('xl-lg-up') {
      height: $secondary-nav-height-desktop;
    }
  }

  &__secondary-nav {
    margin-left: -0.15rem;

    @include media('sm-up') {
      height: $primary-nav-height-md-up;
    }

    @include media('md-up') {
      margin-left: -0.15rem;
    }

    @include media('lg-up') {
      margin-left: -0.25rem;
    }

    @include media('xl-lg-up') {
      height: $secondary-nav-height-desktop;
    }

    &--closed {
      transform: translate3d(0, -3.75rem, 0);
      transform-style: preserve-3d;
      pointer-events: none;
      opacity: 0;
    }

    &--mobile {
      padding-bottom: $site-padding-x-md;
      margin-bottom: $site-padding-x-md;
    }
  }

  &__logo-container {
    height: 1.4rem;
    margin-top: 0.2rem;

    @include media('sm-up') {
      margin-top: 0;
      margin-bottom: 0.2rem;
    }

    @include media('md-up') {
      margin-bottom: 0.1rem;
    }

    @include media('xl-lg-up') {
      height: 2.4rem;
    }
  }

  &__button-container {
    &::before {
      content: '';
      display: block;

      @include media('md-up') {
        margin: -0.2rem 0;
      }

      @include media('xl-lg-up') {
        margin: -0.4rem 0;
      }
    }
  }

  &__button {
    @extend .link--style-hover-black;
  }

  &__secondary-nav-mobile-button {
    &::before {
      content: '';
      display: block;
      margin: -0.1rem 0;
    }
  }

  &__menu-secondary-nav-button {
    &::before {
      content: '';
      display: block;
      margin: -0.7rem 0;

      @include media('xl-lg-up') {
        margin: -1.15rem 0;
      }
    }
  }

  &--menu-active {
    height: 100%;

    @media (min-height: 820px) and (min-width: 640px) {
      height: auto;
    }
  }

  &--full-notification-active {
    @include media('md-up') {
      top: $full-notification-height-tablet;
    }

    @include media('xl-lg-up') {
      top: $full-notification-height-desktop;
    }
  }

  &--notification-marquee-active {
    @include media('md-up') {
      top: $notification-marquee-height-tablet;
    }

    @include media('xl-lg-up') {
      top: $notification-marquee-height-desktop;
    }
  }
}

.nav-line-height-full-crop {
  &::before {
    content: '';
    display: block;
    margin: -0.1rem 0;
  }

  @include media('lg-up') {
    &::before {
      content: '';
      display: block;
      margin: -0.4rem 0;
    }

    &::after {
      content: '';
      display: block;
      margin: -0.4rem 0;
    }
  }
}
