.EventBlock {
  &--border-top-none {
    @include media('md-up') {
      border-top: 0;
    }
  }

  &__text-container {
    @include media('md-up') {
      padding: 0.4rem 4rem 4rem 0.4rem;
    }

    @include media('xl-up') {
      padding: 0.4rem 4rem 7.3rem 0.4rem;
    }

    &--style-white-with-header {
      border-top: $border-black;

      @include media('md-up') {
        border-top: 0;
      }
    }

    &--style-white-no-header {
      border-top: $border-black;
    }

    &--style-black-with-header {
      border-top: $border-white;

      @include media('md-up') {
        border-top: 0;
      }
    }

    &--style-black-no-header {
      border-top: $border-white;
    }
  }
}
