//Borders
$border-black: 1px solid color('black');
$border-green: 1px solid color('green');
$border-white: 1px solid color('white');

.border-black {
  border: $border-black;
}

.border-top-black {
  border-top: $border-black;
}

.border-bottom-black {
  border-bottom: $border-black;
}

.border-right-black {
  border-right: $border-black;
}

.border-left-black {
  border-left: $border-black;
}

.border-green {
  border: $border-green;
}

.border-white {
  border: $border-white;
}

.border-top-white {
  border-top: $border-white;
}

.border-bottom-white {
  border-bottom: $border-white;
}

.border-right-white {
  border-right: $border-white;
}

.border-left-white {
  border-left: $border-white;
}

//Global View
.view {
  min-height: 100vh;

  @include media('md-up') {
    padding-top: 23.8125rem;
  }

  @include media('lg-up') {
    min-height: calc(100vh + 1px);
  }

  @include media('xl-lg-up') {
    padding-top: $nav-notification-fully-open-desktop;
  }

  &--primary-nav-active-notification-marquee-active {
    @include media('lg-md-up') {
      padding-top: $primary-nav-notification-marquee-height-tablet;
    }

    @include media('xl-lg-up') {
      padding-top: $primary-nav-notification-marquee-height-desktop;
    }
  }

  &----notification-bar-inactive-main-menu-active {
    padding-top: $primary-nav-height-sm-up;

    @include media('xl-lg-up') {
      padding-top: $nav-secondary-nav-height-xl-lg-up;
    }
  }

  &--notification-bar-secondary-nav-inactive {
    padding-top: $primary-nav-height-sm-up;

    @include media('xl-lg-up') {
      padding-top: $primary-nav-height-xl-lg-up;
    }
  }

  &--notification-bar-secondary-nav-active {
    padding-top: $primary-nav-height-xs;

    @include media('sm-up') {
      padding-top: $primary-nav-height-sm-up;
    }

    @include media('md-up') {
      padding-top: $nav-notification-fully-open-tablet;
    }

    @include media('xl-lg-up') {
      padding-top: $nav-notification-fully-open-desktop;
    }
  }

  //Notification marquee active, secondary nav active, no sticker
  &--notification-bar-active-no-sticker-secondary-nav-active {
    padding-top: $primary-nav-height-xs;

    @include media('sm-up') {
      padding-top: $primary-nav-height-sm-up;
    }

    @include media('md-up') {
      padding-top: calc(
        #{$notification-marquee-height-tablet} + #{$nav-secondary-nav-height-md-up}
      );
    }

    @include media('xl-lg-up') {
      padding-top: calc(
        #{$notification-marquee-height-desktop} + #{$nav-secondary-nav-height-xl-lg-up}
      );
    }
  }

  &--notification-bar-inactive {
    padding-top: $primary-nav-height-xs;

    @include media('sm-up') {
      padding-top: $primary-nav-height-sm-up;
    }

    @include media('md-up') {
      padding-top: 5.3796875rem;
    }

    @include media('xl-lg-up') {
      padding-top: $nav-secondary-nav-height-xl-lg-up;
    }
  }

  > .GenericPageView > :last-child,
  > .ArticleView > :last-child {
    border-bottom: $border-black;
  }
}

.ArticleView__article-content .text-article-body > :last-child {
  padding-bottom: 2.5rem !important;
}

//Global padding
.site-padding-x {
  padding-left: $site-padding-x-sm;
  padding-right: $site-padding-x-sm;

  @include media('md-up') {
    padding-left: $site-padding-x-md;
    padding-right: $site-padding-x-md;
  }
}

.site-padding-y {
  padding-bottom: $site-padding-x-sm;

  @include media('md-up') {
    padding-bottom: $site-padding-x-md;
  }
}

.site-margin-x {
  margin-left: $site-padding-x-sm;
  //Accommdates borders
  margin-right: calc(#{$site-padding-x-sm} - 1px);

  @include media('md-up') {
    margin-left: $site-padding-x-md;
    margin-right: $site-padding-x-md;
  }
}

.disable-body-scroll {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

//Grid, black
$columns-black: linear-gradient(to right, color('black') 1px, transparent 1px);

$baseline-xs-black: repeating-linear-gradient(
  to bottom,
  color('black'),
  color('black') 1px,
  transparent 0,
  transparent calc(((100vw - #{$site-padding-x-sm-double})) / 4)
);

$baseline-sm-xs-black: repeating-linear-gradient(
  to bottom,
  color('black'),
  color('black') 1px,
  transparent 0,
  transparent calc(((100vw - #{$site-padding-x-sm-double})) / 6)
);

$baseline-md-black: repeating-linear-gradient(
  to bottom,
  color('black'),
  color('black') 1px,
  transparent 0,
  transparent calc(((100vw - #{$site-padding-x-md-double})) / 12)
);

$baseline-xs-black-3-4: repeating-linear-gradient(
  to bottom,
  color('black'),
  color('black') 1px,
  transparent 0,
  transparent calc(72vw / 3)
);

$baseline-md-black-3-4: repeating-linear-gradient(
  to bottom,
  color('black'),
  color('black') 1px,
  transparent 0,
  transparent calc(72vw / 3)
);

.grid-black-3-4-columns {
  background-size: calc((100vw - #{$site-padding-x-sm-double} + 1px) / 4), 100%;
  z-index: -1;
  background-image: $columns-black, $baseline-xs-black;

  @include media('sm-xs-up') {
    background-size: calc((100vw - #{$site-padding-x-sm-double}) / 6), 100%;
    background-image: $columns-black, $baseline-sm-xs-black;
  }

  @include media('md-up') {
    background-size: calc((100vw - #{$site-padding-x-md-double}) / 12), 100%;
    background-image: $columns-black, $baseline-md-black;
  }
}

.grid-black-12-columns {
  background-size: calc((100vw - #{$site-padding-x-sm-double}) / 4), 100%;
  background-image: $columns-black, $baseline-xs-black;

  @include media('sm-xs-up') {
    background-size: calc((100vw - #{$site-padding-x-sm-double}) / 6), 100%;
    background-image: $columns-black, $baseline-sm-xs-black;
  }

  @include media('md-up') {
    background-size: calc((100vw - #{$site-padding-x-md-double}) / 12), 100%;
    background-image: $columns-black, $baseline-md-black;
  }
}

//Grid, white
$columns-white: linear-gradient(to right, color('white') 1px, transparent 1px);

$baseline-xs-white: repeating-linear-gradient(
  to bottom,
  color('white'),
  color('white') 1px,
  transparent 0,
  transparent calc(((100vw - #{$site-padding-x-sm-double})) / 4)
);

$baseline-sm-xs-white: repeating-linear-gradient(
  to bottom,
  color('white'),
  color('white') 1px,
  transparent 0,
  transparent calc(((100vw - #{$site-padding-x-sm-double})) / 6)
);

$baseline-md-white: repeating-linear-gradient(
  to bottom,
  color('white'),
  color('white') 1px,
  transparent 0,
  transparent calc(((100vw - #{$site-padding-x-md-double})) / 12)
);

.grid-white {
  background-size: calc((100vw - #{$site-padding-x-sm-double}) / 4), 100%;
  background-image: $columns-white, $baseline-xs-white;

  @include media('sm-xs-up') {
    background-size: calc((100vw - #{$site-padding-x-sm-double}) / 6), 100%;
    background-image: $columns-white, $baseline-sm-xs-white;
  }

  @include media('md-up') {
    background-size: calc((100vw - #{$site-padding-x-md-double}) / 12), 100%;
    background-image: $columns-white, $baseline-md-white;
  }
}

//Article
.text-article-body > div > h1,
.text-article-body > div > h2,
.text-article-body > div > h3,
.text-article-body > div > h4,
.text-article-body > div > h5,
.text-article-body > div > h6,
.text-article-body > p,
.text-article-body > .ArticleImageBlock,
.text-article-body > .Video,
.text-article-body > .ArticleScheduleBlock,
.text-article-body > .ArticleEventBlock {
  padding-left: 1rem;
  padding-right: 1rem;

  @include media('xl-lg-up') {
    padding-left: 9.375rem;
    padding-right: 30rem;
  }

  @include media('xxxl-up') {
    padding-left: 15rem;
    padding-right: 38rem;
  }
}

.text-article-body > .ArticleImageBlock img {
  @include media('xl-lg-up') {
    max-width: 45rem;
  }

  @include media('xl-up') {
    max-width: 40rem;
  }
}

.text-article-body blockquote {
  margin-left: 1rem;
  margin-right: 1rem;

  @include media('xl-lg-up') {
    margin-left: 9.375rem;
    margin-right: 30rem;
  }

  @include media('xxxl-up') {
    margin-left: 0;
    margin-right: 0;
    padding-left: 1.875rem;
    padding-right: 38rem;
  }
}

.text-article-body > ul {
  margin-left: 4.25rem;

  @include media('xl-lg-up') {
    margin-left: 12.125rem;
    margin-right: 30rem;
  }

  @include media('xxxl-up') {
    margin-left: 17.75rem;
  }
}

.text-article-body > ol {
  margin-left: $ordered-list-padding-left;

  @include media('xl-lg-up') {
    margin-left: 12.125rem;
    margin-right: 30rem;
  }

  @include media('xxxl-up') {
    margin-left: 17.75rem;
  }
}

.ArticleView__article-content > .text-article-body > :last-child,
.TextBlock__article-content > div > :last-child {
  padding-bottom: 3.125rem;

  @include media('md-up') {
    padding-bottom: 9.375rem;
  }
}

// Centered Article Content without related links sidebar
.article-content-col-6 > div > p,
.article-content-col-6 > div > h1,
.article-content-col-6 > div > h2,
.article-content-col-6 > div > h3,
.article-content-col-6 > div > h4,
.article-content-col-6 > div > h5,
.article-content-col-6 > div > h6,
.article-content-col-6 > .ArticleImageBlock > img,
.article-content-col-6 > .text-article-body > .Video,
.article-content-col-6 > .text-article-body > .ArticleEventBlock,
.article-content-col-6 > .text-article-body > .ArticleImageBlock,
.article-content-col-6 > .text-article-body > .ArticleScheduleBlock {
  padding-left: calc(#{$site-padding-x-sm} + 0.625rem);
  padding-right: calc(#{$site-padding-x-sm} + 0.625rem);

  @include media('xl-lg-up') {
    padding-left: 0;
    padding-right: 0;
    margin-left: 15.4375rem;
    margin-right: 15.4375rem;
  }

  @include media('xxxl-up') {
    margin-left: 28rem;
    margin-right: 28rem;
  }
}

.article-content-col-6 > div > blockquote {
  margin-left: calc(#{$site-padding-x-sm} + 0.625rem);
  margin-right: calc(#{$site-padding-x-sm} + 0.625rem);

  @include media('xl-lg-up') {
    margin-left: 15.4375rem;
    margin-right: 15.4375rem;
    padding-right: 0;
  }

  @include media('xxxl-up') {
    margin-left: 28rem;
    margin-right: 28rem;
  }
}

.article-content-col-6 > div > ul,
.article-content-col-6 > div > ol {
  padding-left: 1rem;
  padding-right: 1rem;

  @include media('xl-lg-up') {
    margin-left: 18.1875rem;
    margin-right: 18.1875rem;
  }

  @include media('xxxl-up') {
    margin-left: calc(28rem + #{$ordered-list-padding-left});
    margin-right: calc(28rem + #{$ordered-list-padding-left});
  }
}

.article-links {
  @extend %transition-shortest;
  top: 5.3125rem;

  &--style-no-subheading-notification-active {
    @include media('xxxl-up') {
      top: 9rem;
    }
  }

  &--style-subheading-notification-active {
    top: 7rem;

    @include media('xxxl-up') {
      top: 9rem;
    }
  }

  @include media('xl-lg-up') {
    padding-bottom: 9.375rem;
  }
}

.TextBlock__article-links {
  &--style-notification-active {
    top: 9rem;

    @include media('xxxl-up') {
      top: 12rem;
    }
  }
}

.article-links-container {
  max-width: 18rem;

  @include media('lg-md-up') {
    max-width: 20rem;
  }

  @include media('xxl-up') {
    max-width: 23rem;
  }
}

.safe-visibility-hidden {
  position: absolute;
  text-indent: -10000px;
  white-space: nowrap;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.hyphens {
  hyphens: auto;
}

.link--style-hover-black {
  @extend .transition-shortest;

  &:hover {
    color: color('black');
  }
}

.link--style-hover-bronze-darker {
  @extend .transition-shortest;

  &:hover {
    color: color('bronze-darker');
  }
}

.topics-title-container-padding {
  padding: 0 0.625rem 2.75rem 0.625rem;

  @include media('md-up') {
    padding: 0 0.9375rem 6.625rem 0.9375rem;
  }
}

.block-title-container-padding {
  padding: 0.4rem 0.625rem;

  @include media('md-up') {
    padding: 0 0.625rem 0.625rem 0.625rem;
  }
}

.load-more-container-padding {
  padding: 0 0.625rem 0.6875rem 0.625rem;

  @include media('md-up') {
    padding: 0 0.625rem 2.5rem 0.625rem;
  }
}

.author-list {
  @include media('lg-up') {
    top: -1.5rem;

    > span {
      padding-top: 1.5rem;
    }
  }

  @include media('xxl-up') {
    top: -1.625rem;
    > span {
      padding-top: 1.625rem;
    }
  }
}

//Adds minimum height to image and text modules
.module-image-min-height {
  @include media('md-up') {
    min-height: 24rem;
  }

  @include media('lg-md-up') {
    min-height: 26rem;
  }

  @include media('lg-up') {
    min-height: 32rem;
  }

  @include media('xxl-up') {
    min-height: 35rem;
  }
}

//Fixes Safari bug
.GenericPageView__pagination button {
  margin: 0;
}

//Images
.image-drop-shadow {
  filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.25));
}
