.ArticleStudentWorkBlock {
  &__image-container,
  &__caption {
    max-width: 80vw;

    @include media('sm-up') {
      max-width: 70vw;
    }

    @include media('md-up') {
      max-width: 60vw;
    }
  }

  &__image-container {
    > img {
      height: auto;
      width: 100%;
      object-fit: contain;
    }
  }

  &__block {
    height: auto;
    max-width: 55rem;

    &:nth-of-type(3n + 1) {
      left: 0;

      @include media('md-up') {
        left: 0;
      }

      @include media('xxl-up') {
        left: -1%;
      }
    }

    &:nth-of-type(3n + 2) {
      left: 5%;

      @include media('sm-up') {
        left: 18%;
      }

      @include media('md-up') {
        left: 29%;
      }

      @include media('xxl-up') {
        left: 40%;
      }
    }

    &:nth-of-type(3n + 3) {
      right: 3%;

      @include media('md-up') {
        left: 15%;
      }
    }

    &:nth-of-type(3n + 4) {
      left: 0;

      @include media('md-up') {
        left: 20%;
      }

      @include media('xxl-up') {
        left: 35%;
      }
    }

    &:nth-of-type(3n + 5) {
      left: 5%;

      @include media('md-up') {
        left: 0;
      }

      @include media('xxl-up') {
        left: 2%;
      }
    }

    &:nth-of-type(3n + 6) {
      right: 2%;

      @include media('md-up') {
        left: 28%;
      }

      @include media('xxl-up') {
        left: 35%;
      }
    }
  }

  &__caption {
    margin-top: -0.2rem;

    &--is-active {
      animation: $transition-animate-fade-in;
    }
  }
}
