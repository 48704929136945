.CookieConsent {
  padding: $site-padding-x-md;
  bottom: $site-padding-x-sm;
  right: $site-padding-x-sm-double;
  width: $cookie-consent-width;

  @include media('md-up') {
    bottom: $site-padding-x-md;
    right: $site-padding-x-md-double;
  }
}
