.TextField {
  &--style-primary {
    input,
    textarea {
      @extend .text-meta-xs;
      color: color('bronze');
      border: none;
      background-color: transparent;
      width: 100%;
      font-family: $apercu-mono;
  
      &::placeholder {
        color: color('bronze');
      }
    }
  }

  &--style-large {
    input,
    textarea {
      @extend .text-lg;
      color: color('black');
      border: none;
      background-color: transparent;
      width: 100%;
      font-family: $apercu;
  
      &::placeholder {
        color: color('green');
      }
    }
  }

}
